.footer {
  background-color: #2e3c7c;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.footer-icon-button {
  width: 40px;
  height: 40px;
  color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 0 6px;
  padding: 0;
  font-size: 22px;
  line-height: 50%;
  display: flex;
}

.footer-icon-button:hover, .footer-links-container {
  color: #fff;
}

.footer-links-container > a {
  color: #fff;
  text-decoration: none;
}

/*# sourceMappingURL=contact-page.4df1ab38.css.map */
