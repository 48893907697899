.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #2e3c7c;
}

.footer-icon-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin: 0 6px;
	padding: 0;
	color: white;
	font-size: 22px;
	line-height: 50%;
}

.footer-icon-button:hover {
	color: white;
}

.footer-links-container {
	color: white;
}

.footer-links-container > a {
	color: white;
	text-decoration: none;
}
